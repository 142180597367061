// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submit-js": () => import("./../src/pages/Submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-pages-the-bookshelf-js": () => import("./../src/pages/TheBookshelf.js" /* webpackChunkName: "component---src-pages-the-bookshelf-js" */),
  "component---src-pages-the-process-js": () => import("./../src/pages/TheProcess.js" /* webpackChunkName: "component---src-pages-the-process-js" */),
  "component---src-pages-the-record-js": () => import("./../src/pages/TheRecord.js" /* webpackChunkName: "component---src-pages-the-record-js" */)
}

